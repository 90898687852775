import { template as template_753ae64239154c3d99bc5b2a25c29cbe } from "@ember/template-compiler";
const FKLabel = template_753ae64239154c3d99bc5b2a25c29cbe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
