import { template as template_b358253b3f434098aa4032237bd1aea6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b358253b3f434098aa4032237bd1aea6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
