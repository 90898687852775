import { template as template_db3deb77807044f28901d102ba849206 } from "@ember/template-compiler";
const FKText = template_db3deb77807044f28901d102ba849206(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
