import { template as template_2ad09b6a679d4fda8d849dc0ea3b63ee } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_2ad09b6a679d4fda8d849dc0ea3b63ee(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
